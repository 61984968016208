/* Eric Meyer's Reset CSS v2.0 - http: //cssreset.com */
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans:400,300,700);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
}
html,
body {
  position: relative;
  width: 100%;
  min-height: 100%;
}
button,
input[type=submit],
input[type=button],
.submit,
.btn {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-appearance: none;
  border-radius: 0;
}
input,
textarea {
  font-family: inherit;
  border-radius: 0;
}
::placeholder {
  color: inherit;
  opacity: 1;
}
em {
  font-style: italic;
}
a {
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
p:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
hr {
  display: block;
  height: 2px;
  border: none;
  padding: 0;
  clear: both;
}
svg {
  display: inline-block;
  overflow: hidden;
  width: 100%;
}
body {
  background: #000000 url(../img/body-bg.png) top center no-repeat fixed;
  font-family: 'Merriweather Sans', sans-serif;
  color: #CACACA;
  text-shadow: 1px 1px 1px #000;
  font-size: 12px;
}
a {
  color: #9E1212;
}
a:active {
  border-bottom: dotted 1px;
}
@media only screen and (min-width: 1025px) {
  a:hover {
    border-bottom: dotted 1px;
  }
}
p {
  padding: 5px 10px;
  line-height: 16px;
  text-align: justify;
}
h1,
footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  background: url(../img/backdrop.png) no-repeat;
  background-size: 100% 100%;
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: justify;
}
@media only screen and (max-width: 540px) {
  h1,
  footer {
    font-size: 10px;
  }
}
.grind {
  color: #9E1212;
}
.grind,
strong,
b {
  font-weight: 700;
}
.wrapper {
  position: relative;
  width: 846px;
  margin: 0 auto;
  padding: 0 10px;
}
@media only screen and (max-width: 846px) {
  .wrapper {
    width: 100%;
  }
}
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.site-content {
  flex: 1;
}
.site-header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
@media only screen and (max-width: 540px) {
  .site-header {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 286px;
    padding-bottom: 0px;
  }
}
.logo {
  position: relative;
  display: block;
  padding-top: 25px;
  z-index: 1;
}
.logo:hover {
  border: none;
}
@media only screen and (max-width: 540px) {
  .logo {
    padding-top: 10px;
  }
}
.freaks {
  position: absolute;
  top: 115px;
  left: 175px;
  font-size: 11px;
  text-transform: uppercase;
}
@media only screen and (max-width: 540px) {
  .freaks {
    top: 100px;
    left: 9px;
    width: 80px;
    line-height: 16px;
  }
}
.contacts a {
  font-size: 10px;
  line-height: 20px;
  color: #CACACA;
  text-decoration: none;
  text-transform: uppercase;
}
.social {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  align-self: center;
  top: -12px;
  z-index: 2;
}
@media only screen and (max-width: 540px) {
  .social {
    position: absolute;
    top: 98px;
    right: 6px;
  }
}
.social ul {
  display: flex;
  flex-flow: row;
  list-style: none;
  justify-content: flex-end;
}
.social ul .fb {
  background: url(../img/bar-grind-facebook.png) 0 0 no-repeat;
}
.social ul .ms {
  background: url(../img/bar-grind-myspace.png) 0 0 no-repeat;
}
.social ul .fm {
  background: url(../img/bar-grind-lastfm.png) 0 0 no-repeat;
}
.social ul .rss {
  background: url(../img/bar-grind-rss.png) 0 0 no-repeat;
}
.social ul a {
  display: block;
  width: 34px;
  height: 32px;
}
.social ul a:active {
  background-position: 0 -33px;
  border: none;
}
@media only screen and (min-width: 1025px) {
  .social ul a:hover {
    background-position: 0 -33px;
    border: none;
  }
}
article {
  padding-bottom: 10px;
}
.events {
  display: flex;
  flex-flow: row wrap;
}
.events--upcoming {
  justify-content: flex-start;
  padding-left: 8px;
}
.events--upcoming p {
  padding: 20px 0;
}
.events--past {
  justify-content: space-around;
}
.events__item {
  display: flex;
  flex-flow: row wrap;
}
.events__item .gallery {
  margin-right: 10px;
  max-height: 150px;
}
.events li,
.events a.gallery {
  width: 150px;
}
@media only screen and (max-width: 400px) {
  .events li,
  .events a.gallery {
    width: 140px;
  }
}
.events a.gallery {
  display: block;
  margin-top: 10px;
  box-shadow: 0px 0px 2px #b5b0a7;
  opacity: .6;
  transition: .3s opacity ease;
}
.events a.gallery img {
  width: 100%;
}
.events a.gallery:hover {
  opacity: 1;
  border: none;
}
.events .events_info {
  padding: 10px;
  padding-left: 0px;
  line-height: 16px;
}
.events .events_info .band {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 400px) {
  .events .events_info .band {
    width: 60%;
    margin-bottom: 10px;
  }
}
.events .events_info .band--last {
  margin-bottom: 15px;
}
.events .events_info .band a {
  text-transform: uppercase;
}
